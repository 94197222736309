exports.components = {
  "component---src-components-templates-article-template-article-template-js": () => import("./../../../src/components/templates/ArticleTemplate/ArticleTemplate.js" /* webpackChunkName: "component---src-components-templates-article-template-article-template-js" */),
  "component---src-components-templates-ev-landing-page-ev-landing-page-js": () => import("./../../../src/components/templates/EVLandingPage/EVLandingPage.js" /* webpackChunkName: "component---src-components-templates-ev-landing-page-ev-landing-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-bsc-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/happy-team-web/happy-team-web/src/projects/bsc.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-bsc-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-chabin-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/happy-team-web/happy-team-web/src/projects/chabin.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-chabin-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-evyon-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/happy-team-web/happy-team-web/src/projects/evyon.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-evyon-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-payd-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/happy-team-web/happy-team-web/src/projects/payd.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-payd-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-storefeeder-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/happy-team-web/happy-team-web/src/projects/storefeeder.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-storefeeder-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-worklife-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/happy-team-web/happy-team-web/src/projects/worklife.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-projects-worklife-mdx" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */)
}

